import React from 'react'
import { DefaultSeo } from 'next-seo'
import { AuthContextProvider } from '../context/Auth'

import type { AppProps } from 'next/app'
import { SettingsContext } from '../context/settings'
import { Settings } from '../types'

import 'react-tooltip/dist/react-tooltip.css'
import '../styles/globals.css'
import 'react-datepicker/dist/react-datepicker.css'
import Script from 'next/script'

const defaultData = {
  title: 'PeakLoad | Exclusive Energy Transition News',
  description: 'Exclusive, real-time Power, Energy and Renewables deal flow',
  image:
    'https://global-uploads.webflow.com/6254591f8587150351f86318/626a98a5b4419e0494c788b3_Share%20Image.jpg',
}

function MyApp({
  Component,
  pageProps,
  settings,
}: AppProps & { settings: Settings }) {
  return (
    <AuthContextProvider>
      <SettingsContext.Provider value={pageProps?.settings || settings}>
        <DefaultSeo
          titleTemplate={`%s - ${defaultData.title}`}
          defaultTitle={defaultData.title}
          description={defaultData.description}
          openGraph={{
            ...defaultData,
            type: 'website',
          }}
          twitter={{
            ...defaultData,
            cardType: 'summary_large_image',
          }}
          additionalLinkTags={[
            {
              type: 'image/x-icon',
              rel: 'shortcut icon',
              href: 'https://global-uploads.webflow.com/6254591f8587150351f86318/6266a487059766f1f7130f6b_Peakload%20Favicon.png',
            },
            {
              rel: 'apple-touch-icon',
              href: 'https://global-uploads.webflow.com/6254591f8587150351f86318/6266a5a22ae743abb4f3dbd0_Peakload%20Favicon%202.png',
            },
          ]}
        />
        {/* @ts-ignore */}
        <Component {...pageProps} />
      </SettingsContext.Provider>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID}`}
        onLoad={() => {
          // @ts-ignore
          window.dataLayer = window.dataLayer || []
          function gtag() {
            // @ts-ignore
            dataLayer.push(arguments)
          }
          // @ts-ignore
          gtag('js', new Date())
          // @ts-ignore
          gtag('config', `${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID}`)
        }}
      />
    </AuthContextProvider>
  )
}

export default MyApp
